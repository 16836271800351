import { Box } from '@mui/material';
import { MessageCircle, Settings, Sparkles } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from '../../../i18n';
import { FeedbackLinkButton } from '../../shared/FeedbackLinkButton';
import { SidebarItemLink } from './Sidebar.Item.Link';
import { SidebarSettingsMenu } from './Sidebar.Settings.Menu';

export const SidebarSettings = () => {
  const [settingsMenuOpen, setSettingsMenuOpen] = useState(false);
  const { t } = useTranslation('sidebar');

  return (
    <Box width={'100%'}>
      <FeedbackLinkButton />
      <SidebarItemLink
        icon={<Sparkles size={20} />}
        text={t('route_release_notes')}
        onClick={() => window.open('https://www.metergrid.de/produkte/release-notes')}
      />
      <SidebarItemLink icon={<MessageCircle size={20} />} text={t('route_support')} href={'support'} />
      <SidebarItemLink
        id={'settings-menu-anchor'}
        icon={<Settings size={20} />}
        text={t('settings')}
        onClick={() => setSettingsMenuOpen(true)}
      />

      <SidebarSettingsMenu open={settingsMenuOpen} onToggle={() => setSettingsMenuOpen(false)} />
    </Box>
  );
};
