import {
  BackendFetch,
  CombinedOptions,
  DefaultParamType,
  DevTools,
  FormatSimple,
  Tolgee,
  useTranslate,
} from '@tolgee/react';
import { ReactElement } from 'react';

const TOLGEE_API_URL = import.meta.env.VITE_TOLGEE_API_URL;
const TOLGEE_API_TOKEN = import.meta.env.VITE_TOLGEE_API_TOKEN;

export const tolgee = Tolgee()
  .use(DevTools())
  .use(FormatSimple())
  .use(BackendFetch({ prefix: '/static/i18n' })) // This will be ignored when apiUrl and apiKey are set
  .init({
    /* These will only be set in development mode so the interactive translation editing is enabled */
    apiUrl: TOLGEE_API_URL || undefined,
    apiKey: TOLGEE_API_TOKEN || undefined,
    language: 'de-DE',
    defaultNs: 'shared',
    ns: ['errors', 'meteringDetails'],
  });

// Use dynamic translations only in development and review mode
const useDynamicTranslations = !!TOLGEE_API_URL && !!TOLGEE_API_TOKEN;
if (useDynamicTranslations) {
  import('@tolgee/web/tools').then((module) => {
    tolgee.addPlugin(module.InContextTools());
  });
}

/**
 *  Transation function used outside react components
 *  Use useTranslation() inside react components!
 *  */

export const hooklessT = (namespace: string, key: string, values?: CombinedOptions<DefaultParamType>) => {
  if (values) {
    // Replace undefined values with empty string
    Object.entries(values).forEach(([key, value]) => {
      if (value === undefined) {
        values[key] = '';
      }
    });
  }

  return tolgee.t(key, key, { ns: namespace, ...values });
};

export const useTranslation = (namespace: string) => {
  const { t } = useTranslate(namespace);
  const tolgeeT = (key: string, values?: CombinedOptions<DefaultParamType>) => {
    if (values) {
      // Replace undefined values with empty string
      Object.entries(values).forEach(([key, value]) => {
        if (value === undefined) {
          values[key] = '';
        }
      });
    }
    return t(key, values);
  };

  return {
    tString: tolgeeT,
    t: (key: string, values?: CombinedOptions<DefaultParamType>): string | ReactElement => {
      const translation = tolgeeT(key, values);
      const translationWithLinebreaks = translation.replace(/\n/g, '<br />');
      const hasHtml = /<\/?[a-z][\s\S]*>/i.test(translationWithLinebreaks);

      if (!hasHtml) return translationWithLinebreaks;

      return <div dangerouslySetInnerHTML={{ __html: translationWithLinebreaks }} />;
    },
  };
};
